import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import IntroSection from "./introSection";
import DonationLinks from "./donationLinks";
import UPIQRSection from "./upiQRSection";
import DirectDonation from "./directDonation";
import AlternateDonationMethods from "./alternateDonationMethods";

const DonatePage = (props) => {

  const data = useStaticQuery(graphql`
    query DonatePageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "donate"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
        }
      }
    }
  `);
  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  return (
    <Layout activeLink="/donate" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <div
        className="bg-[url('../img/backgrounds/background5.jpg')] bg-cover bg-[center_top_-20rem] bg-cover bg-no-repeat">
        <IntroSection/>
        <DonationLinks/>
      </div>
      <UPIQRSection/>
      <DirectDonation/>
      <AlternateDonationMethods/>

      <HelpMethodsSection/>
    </Layout>
  );
};

DonatePage.propTypes = {};

export default DonatePage;
