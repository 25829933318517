import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import SectionPicker from "../../components/sectionPicker";

function DonationLinks() {

  const data = useStaticQuery(graphql`
    query DonationLinksQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "donate"}}) {
        frontmatter {
          donationLinks {
            descriptionShort
            amount
            link
            image
          }
        }
      }
    }
  `);
  const {
    donationLinks: _donationLinks,
  } = data.markdownRemark.frontmatter;

  const donationLinks = _donationLinks.map(link => ({
    image: link.image,
    title: (link.amount ? `₹${link.amount} - ` : "Any Amount - ") + link.descriptionShort,
    link: link.link,
  }));

  return (
    <div className="py-10">
      <SectionPicker sections={donationLinks}/>
    </div>
  );
}

export default DonationLinks;
