import React from "react";
import {graphql, useStaticQuery} from "gatsby";

function IntroSection() {

  const data = useStaticQuery(graphql`
    query DonateIntroSectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "donate"}}) {
        frontmatter {
          donateIntroSection
        }
      }
    }
  `);
  const paragraphs = data.markdownRemark.frontmatter.donateIntroSection;

  return (
    <div>
      <div className="w-full text-center p-5">
        <p className="text-xl font-bold text-red-800 mb-1">"{paragraphs[0]}"</p>
        <p className="text-sm">- {paragraphs[1]}</p>
      </div>
      <div className="w-full text-center px-5 pb-5">
        <p className="mb-1">{paragraphs[2]}</p>
        <p>{paragraphs[3]}</p>
      </div>
    </div>
  )
}

export default IntroSection;
